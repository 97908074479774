import React, { Component } from "react";
import Search from "./components/search.js";
import Profile from "./components/profile.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { callAPIuser, callAPIrank, callIsInGame } from "./util";
import Footer from "./components/footer";
import InGame from "./components/inGame";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import CookieConsent from "react-cookie-consent";
import "./Accueil.css";
import teemo from "./assets/teemo.png";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

export default class HomePage extends Component {
  constructor(props) {
    super(props);

    let userN = undefined;
    let localStorage = window.localStorage;
    userN = localStorage.getItem("mainUser");

    this.state = {
      isLoading: true,
      basicUsername: userN,
      infoRank: null,
      infoJoueur: null,
      inGame: null,
    };
  }

  registerBaseUser = async (username) => {
    let localStorage = window.localStorage;
    localStorage.setItem("mainUser", username);
    this.setState({ basicUsername: username });
    let res1 = await callAPIuser(username);
    let res2 = await callAPIrank(res1.id);
    let res3 = await callIsInGame(res1.id);
    this.setState({
      isLoading: false,
      infoRank: res2,
      infoJoueur: res1,
      inGame: res3,
    });
  };

  startLoading = async (username) => {
    let res1 = await callAPIuser(username);
    let res2 = await callAPIrank(res1.id);
    let res3 = await callIsInGame(res1.id);
    this.setState({
      isLoading: false,
      infoRank: res2,
      infoJoueur: res1,
      inGame: res3,
    });
  };

  componentDidMount() {
    if (
      this.state.basicUsername != null &&
      this.state.infoRank == null &&
      this.state.infoJoueur == null
    ) {
      this.startLoading(this.state.basicUsername);
      document.title = this.state.basicUsername + " profile";
    } else {
      document.title = "Home page";
    }
  }

  setInGame = (infoJ) => {
    this.setState({inGame:true,infoJoueur:infoJ});
  }

  render() {
    return (
      <div>
        {!this.state.inGame ? (
          <div>
            {this.state.basicUsername && true ? (
              this.state.isLoading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent:"center",
                    textAlign:"center",
                    flexDirection: "column",
                    height:"100vh",
                    width:"100%",
                    backgroundColor:"rgb(14, 13, 13)",
                    color:"#FFF500",
                    fontSize:"2.5vw",
                    flexWrap:"wrap",
                  }}
                >
                  <img className="teemo" src={teemo} alt="teemo"/>
                </div>
              ) : (
                <div>
                  <Profile
                    goInGame={this.setInGame}
                    infoJoueur={this.state.infoJoueur}
                    infoRank={this.state.infoRank}
                  />
                </div>
              )
            ) : (
              <div className="accueil">
              <div
                className="fondAccueil"
              > 
              </div>

              <div style={{display: "flex",
                  alignItems: "center",
                  flexDirection: "column",flexWrap:"wrap",overflow:"none",marginTop: "300px",padding:"20px 0px",width:"90%"
                }}>
                <div className="filter">
                </div>
                <div className="spanDiv"> 
                <span className="topSpan">
                  MY
                </span>
                <span className="topSpan">
                  LEAGUE
                </span>
                <span className="topSpan">
                  FRIENDS
                </span>
                </div>
                <Search registerName={this.registerBaseUser} />
                </div>

                <CookieConsent
                  location="bottom"
                  buttonText="Accept"
                  style={{display:"flex",flexDirection:"row",alignItems:"center", background: "#2B373B" }}

                  buttonStyle={{ color: "#4e503b", fontSize: "13px", width : 200}}
                  expires={150}
                >
                  This website uses cookies to enhance the user experience. By continuing your visit you agree to the use of them.
                </CookieConsent>
                <Footer
                >
                  All rights reserved. Myleaguefriends.com isn't endorsed by
                  Riot Games and doesn't reflect the views or opinions of Riot
                  Games or anyone officially involved in producing or managing
                  League of Legends. League of Legends and Riot Games are
                  trademarks or registered trademarks of Riot Games, Inc. League
                  of Legends © Riot Games, Inc.
                </Footer>
              </div>
            )}
          </div>
        ) : (
          <InGame
              idJ={this.state.infoJoueur.id}
              infoJ={this.state.infoJoueur.name}
          />
        )}
      </div>
    );
  }
}
//"https://imgur.com/gallery/kPHDsTO"}
