import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import "./specialButton.css";

const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
      },
    },
  },
});

export default class Search extends Component {
  constructor(props) {
    super(props);
    this.state = { username: "" };
  }

  handleChange = (event, values) => {
    if (event.target.value.indexOf("<") == -1) {
      let final = values;

      if (event.target.value !== 0) {
        final = event.target.value;
      }

      this.setState({ username: final });
    }
  };

  startSearch = () => {
    if (this.state.username != "" && this.state.username) {
      this.props.registerName(this.state.username);
    }
  };

  render() {
    return (
      <div className={this.props.prof ? "spaceDiv spaceDiv2" : "spaceDiv"}>
        <input
          className={this.props.prof ? "input input2" : "input"}
          type="text"
          placeholder={this.props.prof ? "Enter a friend username / pro username":"Enter your LoL username"}
          onChange={this.handleChange}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              this.startSearch();
            }
          }}
        />
        <button
          className={this.props.prof ? "specialButton specialButton2" : "specialButton"}
          onClick={this.startSearch}
        >
          ADD PROFILE
        </button>
      </div>
    );
  }
}
