import React, { Component } from "react";
import MainATH from "./mainATH";

export default ({idJ,infoJ}) => {

  let allUsers = {idJ,infoJ};


  return (
    <div>
    <MainATH users={allUsers}/>
    
    </div>
  );
};
