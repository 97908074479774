import React, { Component } from "react";
import TeamProfile from "./teamProfile";
import Backscreen from "../assets/backInGame.jpg";
import Typography from "@material-ui/core/Typography";
import { getChampionsInfo, callIsInGame } from "../util";
import Footer from "./footer"

export default class MainATH extends Component {
  constructor(props) {
    super(props);

    this.state = { goHome:false, gameInfo: undefined, champsInfo: undefined };
  }

  startSearchingApi = async () => {

    console.log(this.props,this.props.users.idJ)
    let res1 = await getChampionsInfo();
    let res2 = await callIsInGame(this.props.users.idJ);

    this.setState({ gameInfo: res2, champsInfo: res1 });
  };

  componentDidMount() {
    document.title = 'Game info : '+this.props.users.infoJ;
    this.startSearchingApi();
  }

  render() {
    let gameLength = 0;
    let i = 0;
    let j = 0;

    if(this.state.goHome) {
      window.location.reload();
    } 

    return (
      <div style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        minHeight:"100vh",
        position:"relative"}}>
      <div
        style={{
          backgroundImage: "url(" + Backscreen + ")",
          position: "fixed",
          bottom: 0,
          right: 0,
          left: 0,
          top: 0,
          overflow:"hidden",
          zIndex:-2,
        }}
      >
        <div style={{
          position: "fixed",
          bottom: 0,
          right: 0,
          left: 0,
          top: 0,
          backgroundColor:"rgb(255,255,255,0.2)",
          zIndex:-1,
        }} >

        </div>
      </div>

        
        <Typography style={{ marginTop: 60 }} variant="h4" gutterBottom>
          In game for{" "}
          {this.state.gameInfo ? Math.floor(this.state.gameInfo.gameLength / 60): "-"} minutes
        </Typography>
        
        {this.state.gameInfo ? (
    <React.Fragment>
    <div
              style={{
                marginTop: 15,
                display: "flex",
                flexDirection: "row",
                flexWrap:"wrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {this.state.gameInfo.participants.map((elem) => {
                let team = 0;
                if (i <= 4) {
                  i++;
                  return (
                    <TeamProfile
                      part={team}
                      userId={elem.summonerId}
                      champions={this.state.champsInfo}
                      champId={elem.championId}
                    />
                  );
                }
              })}
            </div>
            <div
              style={{
                marginTop: 60,
                display: "flex",
                flexDirection: "row",
                flexWrap:"wrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {this.state.gameInfo.participants.map((elem) => {
                let team = 1;
                if (j > 4) {
                  return (
                    <TeamProfile
                      part={team}
                      userId={elem.summonerId}
                      champions={this.state.champsInfo}
                      champId={elem.championId}
                    />
                  );
                }
                j++;
              })}

            </div >
            <a style={{zIndex:"1"}} href={"https://porofessor.gg/fr/live/euw/" + this.props.users[1]} > Click here to see the Porofessor analysis </a>

            <a style={{zIndex:"1"}} onClick={() => this.setState({goHome:true})}> Return home </a>
            </React.Fragment>
        ) : (
          <div> 
          "Loading"
          <a style={{paddingBottom:"50px"}} onClick={() => this.setState({goHome:true})}> Return home </a>
          </div>

        )}
        <Footer link={"https://imgur.com/gallery/kPHDsTO"}>
    All rights reserved.
    Myleaguefriends.com isn't endorsed by Riot Games and doesn't reflect the views or opinions of Riot Games or anyone officially involved in producing or managing League of Legends. League of Legends and Riot Games are trademarks or registered trademarks of Riot Games, Inc. League of Legends © Riot Games, Inc.
    </Footer>
      </div>
    );
  }
}
